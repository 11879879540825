.home1,.home2,.home3{
    height: 100vh;
    width: 100%;
}
body{
    background-color: rgba(128, 136, 252, 0.877);
}
.home1{
    /* background: url(../../public/images/TheOcean.jpg) no-repeat center/cover; */
    background-image: url(../../public/istockphoto-1450052495-1024x1024.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home1.home1-inner{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home1-inner h1{
font:bold 4rem Roboto;
color:rgb(255,255,255);
background-color: rgb(0,0,0,0.3);
padding: 1rem;
}
.home1-inner p{
    color: #ffffff;
    letter-spacing: 1.5px;
    word-spacing: .5rem;
    margin-top: 5px;

}
.home1-inner p:hover{
    color: rgb(255,255,255,0.5);
    cursor: pointer;
}
.home1-inner h1:hover{
    filter:invert();
}
.home2{
    background-color: #ffffff;
    width: 70%;
    border-radius: 0px 100px 100px 0px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 2rem;

}
.home2 h1{
letter-spacing: 2px;    
border-bottom: 5px ridge black;
text-align: center;
margin-bottom: 7px;

}

.home2 p{
    letter-spacing: 2px;
    word-spacing: 2px;
    text-align: center;
    line-height: 30px;
}
.btn{
    background-color: rgb(22, 164, 123);
    padding: .5rem 1rem;
    margin-top: 2rem;
    border: none;
    border-radius: 3px;
    text-align: center;

}
.main{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100%;
}
.home3{
    display: flex;
    justify-content: right;
    align-items: center;
    

}
.home3-inner{
    background-color: #ffffff;
    width: 70%;
    border-radius: 100px 0px 0px 100px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 3rem;
}
.brands{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    flex-wrap: wrap;
}

.brand{
    text-align: center;
    background-color: black;
    color: #ffffff;
    border-radius: 50%;
    height: 7rem;
    width: 7rem;
    display: flex;
    flex-direction:column;   
    justify-content: center;
    align-items: center;    
    margin-top: 10px;
    transition: all .5s;
    animation: poptop infinite alternate-reverse linear 1s;
    flex-wrap: wrap;
    
}
.icons{
    font-size: 3rem;
}
.brand p{
    font-weight: bold;
    margin-top: 7px;
}

.brand:hover{
    filter:invert();
}
@keyframes poptop{
    to{
        transform: translatey(10px);
    }
}

footer{
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 0rem;
    flex-wrap: wrap;
}
.footer-main1{
    text-align: center;
    line-height: 1.5rem;
    color: black;
}

.footer-main2{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.footer-main2 h2{
    margin-bottom: 5px;
}

.footer-main2 a{
    font:bold 1rem cursive;
    color: black;
}
.footer-main2 a:hover{
    text-decoration: underline;
}




