.contact-inner{
    background-color: white;
    height: 70vh;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 150px 5px 150px 5px;
    
}
.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}
.contact h1{
    color: white;
    margin-bottom: 5px;
    cursor: pointer;
}
.form-group{
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    
      
   
    
}
.form-group label{
    font:bold 1rem cursive;
    width: 40%;
    margin-bottom: 4px;
}
.form-group input{
    padding: 10px 20px;
    flex-grow: 1; 
    outline: none;   
    border: none;   
    box-shadow: 0px 0px 2px black;
    border-radius: 5px;
}
.form-submit button{
    background-color: rgb(181, 0, 205);
    border: none;
    padding: 10px 2rem;
    margin: 1rem auto;
    display: block;
    border-radius: 10px;
    cursor: pointer;

}