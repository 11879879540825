.nav{
    display: flex;
    position: sticky;
    top: 0%;
    background-color: aliceblue;
    height: 5rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 1;


}
.nav-links{
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;

}
.nav-links li {
    text-decoration: none;

    margin-right: 2rem;
    
}



.logo{
    
    font: 1.5rem bold;
    
}

.active{
    color: rgb(255, 45, 143);
    font-weight: bolder;

    }



    @media(max-width:650px){
        .logo{
            font-size: 1rem;
            text-decoration: none;
        }
      }